import React from 'react';

export default class AboutOne extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($(".video-popup").length) {
            $(".video-popup").magnificPopup({
                type: "iframe",
                mainClass: "mfp-fade",
                removalDelay: 160,
                preloader: true,
                fixedContentPos: false,
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="about-one about-three">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="about-one__left wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                                    <div className="about-one__img-box">
                                        <div className="about-one__img">
                                            <img src={publicUrl+"assets/images/resources/about-1.jpg"} alt="" />
                                        </div>
                                        <div className="about-one__small-img wow zoomIn animated animated" data-wow-delay="500ms"
                                            data-wow-duration="2500ms">
                                            <img src={publicUrl+"assets/images/resources/about-1-1.jpg"} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="about-one__right">
                                    <div className="about-one__right-content">
                                        <div className="section-title text-left">
                                            <span className="section-title__tagline">About Us</span>
                                            <h2 className="section-title__title"></h2>
                                        </div>
                                        <p className="about-one__text">The Liberia Female Law Enforcement Association (LIFLEA) is a non-governmental and non-for-profit organization 
                                        established on December 8, 2000, as the umbrella organization to protect and promote the interest and wellbeing of female law enforcement 
                                        officers. Over the past twenty-three years, LIFLEA has consistently worked with stakeholders to advance policy reforms and engage in continuous 
                                        advocacy efforts that focus on gender mainstreaming and the promotion of the rights of women in law enforcement in Liberia. These endeavours 
                                        aim to strengthen efforts in eliminating all forms of gender-based violence and discrimination against female law enforcement officers and 
                                        creating equal opportunities for women to advance professionally. LIFLEA also support efforts and initiatives aimed at advocating and 
                                        protecting the rights of women and children, including girls, against all types of violence.</p>
                                        <ul className="list-unstyled about-one__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-comment"></span>
                                                </div>
                                                <div className="text">
                                                    <h4>Our Mission:</h4>
                                                    <p>Our mission is to advocate for, foster initiatives, and advance policy recommendations that are critical to addressing issues 
                                                        and gaps confronting the growth unobstructed and equal participation of women in Liberia’s security sector.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-comment"></span>
                                                </div>
                                                <div className="text">
                                                    <h4>Our Vision:</h4>
                                                    <p>Our vision is a gender responsive security sector that prioritizes equal opportunities and protect the rights 
                                                        of women in law enforcement</p>
                                                </div>
                                            </li>
                                        </ul>
                                        {/* <div className="about-one__bottom-video-box">
                                            <div className="about-one__btn-box">
                                                <a href="about.html" className="thm-btn about-one__btn">About More</a>
                                            </div>
                                            <div className="about-one__video-link">
                                                <a href="https://www.youtube.com/watch?v=Get7rqXYrbQ" className="video-popup">
                                                    <div className="about-one__video-icon">
                                                        <span className="fa fa-play"></span>
                                                        <i className="ripple"></i>
                                                    </div>
                                                </a>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}