import React from 'react'
import {Link} from'react-router-dom'

export default class UpdateContentOne extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($(".video-popup").length) {
            $(".video-popup").magnificPopup({
                type: "iframe",
                mainClass: "mfp-fade",
                removalDelay: 160,
                preloader: true,
                fixedContentPos: false,
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="update-content-one  about-three">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="update-content-one__left wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                                    <div className="update-content-one__img-box">
                                        <div className="update-content-one__img">
                                            <img src={publicUrl+"assets/images/resources/about-1.jpg"} alt="" />
                                        </div>
                                        <div className="update-content-one__small-img wow zoomIn animated animated" data-wow-delay="500ms"
                                            data-wow-duration="2500ms">
                                            <img src={publicUrl+"assets/images/resources/about-1-1.jpg"} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="update-content-one__right">
                                    <div className="update-content-one__right-content">
                                        <div className="section-title text-left">
                                            {/* <span className="section-title__tagline">About Us</span> */}
                                            <h2 className="section-title__title">LIFLEA works in partnership with various organizations to identify and provide support for women in law enforcement..</h2>
                                        </div>
                                        <p className="update-content-one__text">To increase the likelihood of law enforcement women being eligible for peacekeeping 
                                        missions, a group of over 40 law enforcement women from LNP, LIS, and BCR are currently undergoing defensive driving training 
                                        at the Liberia National Police Training Academy School.</p>
                                        <ul className="list-unstyled update-content-one__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-comment"></span>
                                                </div>
                                                <div className="text">
                                                    <h4>Empowering Female Law Enforcement Oficers</h4>
                                                    <p>LIFLEA works in partnership with various organizations 
                                                        to identify and provide support for women in law enforcement, 
                                                        enabling them to enhance their professional skills and increase 
                                                        their prospects for career progression.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-comment"></span>
                                                </div>
                                                <div className="text">
                                                    <h4>Building capacity of Female Fire Fighters</h4>
                                                    <p> A significant milestone has been achieved at LNFS as 
                                                        27 women firefighters are being trained to acquire the 
                                                        necessary skills and technical expertise to operate fire 
                                                        engines and ambulances.</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="update-content-one__bottom-video-box">
                                            <div className="update-content-one__btn-box">
                                                {/* <a href="#" className="thm-btn update-content-one__btn">About More</a> */}
                                                <Link className="thm-btn update-content-one__btn" to={process.env.PUBLIC_URL + `/about`}>About More</Link>
                                            </div>
                                            <div className="update-content-one__video-link">
                                                <a href="https://www.youtube.com/watch?v=YWg94noUhho" className="video-popup">
                                                    <div className="update-content-one__video-icon">
                                                        <span className="fa fa-play"></span>
                                                        <i className="ripple"></i>
                                                    </div>
                                                </a>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
