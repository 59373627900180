import React from 'react';
import { Link } from 'react-router-dom';

export default class CounterOne extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($(".odometer").length) {
            var odo = $(".odometer");
            odo.each(function () {
              $(this).appear(function () {
                var countNumber = $(this).attr("data-count");
                $(this).html(countNumber);
              });
            });
        }
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="counter-one">
                    <div className="counter-one-bg jarallax" data-jarallax data-speed="0.2" data-imgPosition="50% 0%" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/counter-one-bg.jpg)'}}></div>
                    <div className="container">
                        <div className="section-title text-center">
                            <span className="section-title__tagline"></span>
                            <h2 className="section-title__title">We’re leading a global community of humanitarians <br/>to protect and promote the interest and wellbeing of <br/>female law enforcement officers.</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft" data-wow-delay="100ms">
                                {/* Counter One Single */}
                                <div className="counter-one__single">
                                    <div className="counter-one__content">
                                        <h3 className="odometer" data-count="700">00</h3>
                                        <span className="counter-one__letter">+</span>
                                        <p className="counter-one__text">Scholarship Beneficiaries</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft" data-wow-delay="200ms">
                                {/* Counter One Single */}
                                <div className="counter-one__single">
                                    <div className="counter-one__content">
                                        <h3 className="odometer" data-count="10">00</h3>
                                        <span className="counter-one__letter">+</span>
                                        <p className="counter-one__text">Counties with LIFLEA Presence</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft" data-wow-delay="300ms">
                                {/* Counter One Single */}
                                <div className="counter-one__single">
                                    <div className="counter-one__content">
                                        <h3 className="odometer" data-count="4">00</h3>
                                        <span className="counter-one__letter">+</span>
                                        <p className="counter-one__text">Association Group</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft" data-wow-delay="400ms">
                                {/* Counter One Single */}
                                <div className="counter-one__single">
                                    <div className="counter-one__content">
                                        <h3 className="odometer" data-count="1700">00</h3>
                                        <span className="counter-one__letter">+</span>
                                        <p className="counter-one__text">LIFLEA Members</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="counter-one__btn-box text-center">
                                    <Link to={process.env.PUBLIC_URL + `/what-we-do`} className="thm-btn counter-one__btn">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}