import React from 'react';
import {Link} from 'react-router-dom';
import BlogCategory from '../blog-sidebar/BlogCategory';
import BlogComments from '../blog-sidebar/BlogComments';
import BlogLatestPost from '../blog-sidebar/BlogLatestPost';
import BlogQuery from '../blog-sidebar/BlogQuery';
import BlogSearch from '../blog-sidebar/BlogSearch';
import BlogTags from '../blog-sidebar/BlogTags';

export default class SingleBlogDetails extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="blog-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-7">
                                <div className="blog-details__left">
                                    <div className="blog-details__img">
                                        <img src={publicUrl+"assets/images/blog/blog-details-1.jpg"} alt="" />
                                        <div className="blog-details__date">
                                            <p>27 <br /> Feb</p>
                                        </div>
                                    </div>
                                    <div className="blog-details__content">
                                        <ul className="blog-details__meta list-unstyled">
                                            <li><Link to={process.env.PUBLIC_URL + `/blog-details`}><i className="far fa-comment"></i><span>(2)</span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/blog-details`}><i className="far fa-thumbs-up"></i><span>(2)</span></Link></li>
                                        </ul>
                                        <h3 className="blog-details__title">Continued Digital Litracy Trainning for Liberia Female Law Enforcement Officers.</h3>
                                        <p className="blog-details__text-1">As part of ongoing capacity development training for female law enforcement officers and members of LIFLEA, about 27 female fire fighters are being trained in specialized 
                                        driving at the Liberia National Fire Service Training Academy. This initiative is being supported by the Bureau of International Narcotics and Law Enforcement Affairs (INL) of the US States Department through 
                                        the International Development Law Organization (IDLO). The initiative is part of the Professional Development Fund (PDF) scholarship which is a component of the Strengthening Gender Equality in Liberia's Security 
                                        Sector-SEGESS project, and aims at providing female fire fighters  skills required to effectively operate fire engines and ambulances. For the first time, the LNFS will have 27 female officers from across Liberia 
                                        train with skill to move and operate fire engines. This will  provide them the opportunity to increase their chances of promotion and advance themselves professionally.  </p>
                                        <p className="blog-details__text-2"> The beneficiaries of this training to include; Joan Nienekai Attia Sonnie D Forkpa Rose 
                                        Youanabo Yatta Mark Annie Kuo Catherine C. Gbezial Varee Brown Comfort K Gopea Bendu H. Bayoh Kebeh Mamai Johnson Boimah Fahnbulleh Flourish Kadiatu Flourish Izetta Martin Sylvester Kankawah Sr. Marie Foeday 
                                        Lulu Yei Rogers Veronica MB Carlos Hawa Sirleaf Cecelia Mccoy Minnie ANNUAL RETREAT AND GENERAL ASSEMBLY. </p>
                                    </div>
                                    <div className="blog-details__content-two">
                                        <div className="blog-details__content-two-img">
                                            <img src={publicUrl+"assets/images/blog/blog-details-2.jpg"} alt="" />
                                        </div>
                                        <div className="blog-details__content-two-details">
                                            <h3 className="blog-details__content-two-title">Events Organized</h3>
                                            <p className="blog-details__content-two-text-1">IDLO supported LIFLEA and GSSNT in organizing two events in observance of this year’s 16 Days of Activism. The support was provided under the “Strengthening Gender Equality 
                                             in Liberia’s Security Sector” project which is being supported by the United States Department of State Bureau of International Narcotics and Law Enforcement Affairs (INL) through IDLO. In line with the official dates 
                                             for the observance of the 16 Days of Activism (November 25 – December 10), LIFLEA and GSSNT convened two mutually reinforcing events to emphasize the importance of women in the security sector and to reaffirm joint 
                                             commitments in advocating and leading on policy reforms initiatives for the benefit of women in the security sector.  </p>
                                            <p className="blog-details__content-two-text-2">Between 4-6 December 2023, LIFLEA and GSSNT conducted a pre-recorded interview that was aired on multiple radio stations and published in several newspapers. This was followed 
                                            by a one-day dialogue, which included a panel discussion that brought together over one hundred-twenty (120) participants, including female law enforcement officers and sectors specialists with extensive expertise on security 
                                            and law enforcement, justice and the rule of law, gender mainstreaming and gender-responsive budgeting. The events were convened under the theme “Strengthening Gender Equality in Liberia’s Security Sector” and was aligned to 
                                            the global UNiTE campaign theme for 2023; “Invest to Prevent Violence Against Women and Girls”. 
                                            Aligned to the overarching goal of the SEGESS project and the 2023 global UNiTE campaign theme, the overall objectives of the events convened by LIFLEA and GSSNT were twofold:</p>
                                            <ul className="list-unstyled blog-details__content-two-points">
                                                <li>To amplify the voices of women in the Liberia’s security sector by calling for the prevention and elimination of discrimination and violence against women in the security sector and beyond;</li>
                                                <li>To collectively explore options and elicit expert opinions on ways to bridge gaps and strengthen security institutions to ensure the prioritization of the roles and functions of women in the security sector of Liberia.</li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="blog-details__bottom">
                                        <p className="blog-details__tags">
                                            <span>Tags</span>
                                            <a href="#">Liberia Fire Service</a>
                                            <a href="#">Liberia National Police</a>
                                        </p>
                                        <div className="blog-details__social-list">
                                            <a href="#"><i className="fab fa-twitter"></i></a>
                                            <a href="https://www.facebook.com/p/Liberia-Female-Law-Enforcement-Association-LIFLEA-100069295507858/?paipv=0&eav=AfYaUM_Xm4ARXU_7AQofVV4gftD8YlWVzJz7GijtKAKAGdjp3zdpvGvSbXEH5BWGPks&_rdr"><i className="fab fa-facebook"></i></a>
                                            {/* <a href="#"><i className="fab fa-pinterest-p"></i></a> */}
                                            <a href="#"><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                    <div className="author-one">
                                        <div className="author-one__image">
                                            <img src={publicUrl+"assets/images/blog/author-1.jpg"} alt="" />
                                        </div>
                                        <div className="author-one__content">
                                            <h3>Dave Davidson</h3>
                                            <p>Blog and Social Media Campaign Specialists.</p>
                                        </div>
                                    </div>
                                    <div className="comment-one">
                                        <h3 className="comment-one__title">2 Comments</h3>
                                        <div className="comment-one__single">
                                            <div className="comment-one__image">
                                                <img src={publicUrl+"assets/images/blog/comment-1.jpg"} alt="" />
                                            </div>
                                            <div className="comment-one__content">
                                                <h3>Marie Foeday</h3>
                                                <p>Women bring unique perspectives, skills, and approaches to law enforcement. A diverse force is better equipped to handle a variety of situations and challenges, leading to more effective and balanced outcomes.</p>
                                                <span>February 26. 2024</span>
                                                <Link to={process.env.PUBLIC_URL + `/blog-details`} className="thm-btn comment-one__btn">Reply</Link>
                                            </div>
                                        </div>
                                        <div className="comment-one__single">
                                            <div className="comment-one__image">
                                                <img src={publicUrl+"assets/images/blog/comment-2.jpg"} alt="" />
                                            </div>
                                            <div className="comment-one__content">
                                                <h3>Nowah Cole</h3>
                                                <p>Thanks for the job well done 👍.</p>
                                                <span>March 06. 2024</span>
                                                <Link to={process.env.PUBLIC_URL + `/blog-details`} className="thm-btn comment-one__btn">Reply</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="comment-form">
                                        <h3 className="comment-form__title">Leave A Reply</h3>
                                        <form action="#" className="comment-one__form contact-form-validated" novalidate="novalidate">
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="Your Name" name="name" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12">
                                                    <div className="comment-form__input-box">
                                                        <input type="email" placeholder="Email Address" name="email" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="comment-form__input-box comment-form__textarea">
                                                        <textarea name="message" placeholder="Write a Message"></textarea>
                                                    </div>
                                                    <div className="comment-form__btn-box">
                                                        <button type="submit" className="thm-btn comment-form__btn">Post Comment</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                <div className="sidebar">
                                    <BlogSearch />
                                    <BlogCategory />
                                    <BlogLatestPost />
                                    <BlogQuery />
                                    <BlogTags />
                                    <BlogComments />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}