import React from 'react';
import { Link } from 'react-router-dom';

export default class RecentEventsOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="recent-event">
                    <div className="container">
                        <div className="section-title text-center">
                            <span className="section-title__tagline">Our Recent events</span>
                            <h2 className="section-title__title">Capacity Development and <br /> Career Advancement.</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay="100ms">
                                {/* Recent Event Single */}
                                <div className="recent-event__single">
                                    <div className="recent-event__img">
                                        <img src={publicUrl+"assets/images/resources/recent-event-1.jpg"} alt="" />
                                        <div className="recent-event__btn-box">
                                            <Link to={process.env.PUBLIC_URL + `/blog-details`} className="thm-btn recent-event__btn">Read More</Link>
                                        </div>
                                    </div>
                                    <div className="recent-event__content">
                                        <ul className="list-unstyled recent-event__meta">
                                            <li>
                                                <p> <span className="icon-calendar"></span> 24 Feb 2024</p>
                                            </li>
                                            <li>
                                                <p> <span className="icon-back-in-time"></span> 1:00 pm </p>
                                            </li>
                                        </ul>
                                        <h3 className="recent-event__title"><Link to={process.env.PUBLIC_URL + `/blog-details`}>Capacity Building</Link></h3>
                                        <p className="recent-event__text">Strengthening and Realigning LIFLEA’s Institutional Capacity for Improve and Effective Coordination among Female Law Enforcement Officers.</p>
                                    </div>
                                </div>
                                {/* Recent Event Single */}
                                <div className="recent-event__single">
                                    <div className="recent-event__img">
                                        <img src={publicUrl+"assets/images/resources/recent-event-2.jpg"} alt="" />
                                        <div className="recent-event__btn-box">
                                            <Link to={process.env.PUBLIC_URL + `/blog-details`} className="thm-btn recent-event__btn">Read More</Link>
                                        </div>
                                    </div>
                                    <div className="recent-event__content">
                                        <ul className="list-unstyled recent-event__meta">
                                            <li>
                                                <p> <span className="icon-calendar"></span> 25 May 2023</p>
                                            </li>
                                            <li>
                                                <p> <span className="icon-back-in-time"></span> 11:00 am </p>
                                            </li>
                                        </ul>
                                        <h3 className="recent-event__title"><Link to={process.env.PUBLIC_URL + `/blog-details`}>Driving Training</Link></h3>
                                        <p className="recent-event__text">LIFLEA conducted defensive driving training to a group of over 40 law enforcement women from LNP, LIS, and BCR at the Liberia National Police Training Academy School.</p>
                                    </div>
                                </div>
                                {/* Recent Event Single */}
                                <div className="recent-event__single">
                                    <div className="recent-event__img">
                                        <img src={publicUrl+"assets/images/resources/recent-event-3.jpg"} alt="" />
                                        <div className="recent-event__btn-box">
                                            <Link to={process.env.PUBLIC_URL + `/blog-details`} className="thm-btn recent-event__btn">Read More</Link>
                                        </div>
                                    </div>
                                    <div className="recent-event__content">
                                        <ul className="list-unstyled recent-event__meta">
                                            <li>
                                                <p> <span className="icon-calendar"></span> 20 February 2024</p>
                                            </li>
                                            <li>
                                                <p> <span className="icon-back-in-time"></span> 10:00 am </p>
                                            </li>
                                        </ul>
                                        <h3 className="recent-event__title"><Link to={process.env.PUBLIC_URL + `/blog-details`}>Annual Retreat</Link></h3>
                                        <p className="recent-event__text">LIFLEA hosted over 100 female law enforcement officers at its Annual Retreat and General Assembly held at the Regional Security Hub in Gbarnga, Bong County .</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay="300ms">
                                {/* Recent Event Single */}
                                <div className="recent-event__single">
                                    <div className="recent-event__img">
                                        <img src={publicUrl+"assets/images/resources/recent-event-4.jpg"} alt="" />
                                        <div className="recent-event__btn-box">
                                            <Link to={process.env.PUBLIC_URL + `/blog-details`} className="thm-btn recent-event__btn">Read More</Link>
                                        </div>
                                    </div>
                                    <div className="recent-event__content">
                                        <ul className="list-unstyled recent-event__meta">
                                            <li>
                                                <p> <span className="icon-calendar"></span> 25 June 2023</p>
                                            </li>
                                            <li>
                                                <p> <span className="icon-back-in-time"></span> 09:00 am </p>
                                            </li>
                                        </ul>
                                        <h3 className="recent-event__title"><Link to={process.env.PUBLIC_URL + `/blog-details`}>Advocacy</Link></h3>
                                        <p className="recent-event__text">LIFLEA collaborates with local women's groups to carry out outreach activities and increase awareness on Sexual and Gender Based Violence (SGBV).</p>
                                    </div>
                                </div>
                                {/* Recent Event Single */}
                                <div className="recent-event__single">
                                    <div className="recent-event__img">
                                        <img src={publicUrl+"assets/images/resources/recent-event-5.jpg"} alt="" />
                                        <div className="recent-event__btn-box">
                                            <Link to={process.env.PUBLIC_URL + `/blog-details`} className="thm-btn recent-event__btn">Read More</Link>
                                        </div>
                                    </div>
                                    <div className="recent-event__content">
                                        <ul className="list-unstyled recent-event__meta">
                                            <li>
                                                <p> <span className="icon-calendar"></span> 11 March 2024</p>
                                            </li>
                                            <li>
                                                <p> <span className="icon-back-in-time"></span> 10:00 am </p>
                                            </li>
                                        </ul>
                                        <h3 className="recent-event__title"><Link to={process.env.PUBLIC_URL + `/blog-details`}>Gender Equality</Link></h3>
                                        <p className="recent-event__text">The surest way to support gender equality and mainstreaming efforts in Liberia's Security Sector, is through capacity development 
                                        which includes empowering women with needed skills.</p>
                                    </div>
                                </div>
                                {/* Recent Event Single */}
                                <div className="recent-event__single">
                                    <div className="recent-event__img">
                                        <img src={publicUrl+"assets/images/resources/recent-event-6.jpg"} alt="" />
                                        <div className="recent-event__btn-box">
                                            <Link to={process.env.PUBLIC_URL + `/blog-details`} className="thm-btn recent-event__btn">Read More</Link>
                                        </div>
                                    </div>
                                    <div className="recent-event__content">
                                        <ul className="list-unstyled recent-event__meta">
                                            <li>
                                                <p> <span className="icon-calendar"></span> 27 Feb 2024</p>
                                            </li>
                                            <li>
                                                <p> <span className="icon-back-in-time"></span> 12:00 pm </p>
                                            </li>
                                        </ul>
                                        <h3 className="recent-event__title"><Link to={process.env.PUBLIC_URL + `/blog-details`}>Pushing the Agenda</Link></h3>
                                        <p className="recent-event__text">Uniting to push the agenda of women in law enforcement! Develop a communique which outlines deliberate actions to be prioritized by the Government of Liberia to address issues 
                                        faced by women in law enforcement sector.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}