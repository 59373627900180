import React from 'react';
import { Link } from 'react-router-dom';

export default class DonationListContent extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($("#donate-amount__predefined").length) {
            let donateInput = $("#donate-amount");
            $("#donate-amount__predefined")
              .find("li")
              .on("click", function (e) {
                e.preventDefault();
                let amount = $(this).find("a").text();
                donateInput.val(amount);
                $("#donate-amount__predefined").find("li").removeClass("active");
                $(this).addClass("active");
            });
        }

        if ($(".progress-levels .progress-box .bar-fill").length) {
            $(".progress-box .bar-fill").each(
              function () {
                $(".progress-box .bar-fill").appear(function () {
                  var progressWidth = $(this).attr("data-percent");
                  $(this).css("width", progressWidth + "%");
                });
              }, {
                accY: 0
              }
            );
        }

        if ($(".count-bar").length) {
            $(".count-bar").appear(
              function () {
                var el = $(this);
                var percent = el.data("percent");
                $(el).css("width", percent).addClass("counted");
              }, {
                accY: -50
              }
            );
        }

        if ($(".count-box").length) {
            $(".count-box").appear(
              function () {
                var $t = $(this),
                  n = $t.find(".count-text").attr("data-stop"),
                  r = parseInt($t.find(".count-text").attr("data-speed"), 10);
        
                if (!$t.hasClass("counted")) {
                  $t.addClass("counted");
                  $({
                    countNum: $t.find(".count-text").text()
                  }).animate({
                    countNum: n
                  }, {
                    duration: r,
                    easing: "linear",
                    step: function () {
                      $t.find(".count-text").text(Math.floor(this.countNum));
                    },
                    complete: function () {
                      $t.find(".count-text").text(this.countNum);
                    }
                  });
                }
              }, {
                accY: 0
              }
            );
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="donations-list">
                    <div className="container">
                        <div className="donations-list__inner">
                            {/* Donations List Single */}
                            <div className="donations-list__single">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="donations-list__right">
                                            <div className="donations-list__content">
                                                <div className="donations-list__category">
                                                    <p>Advocacy</p>
                                                </div>
                                                <h3 className="donations-list__title"><Link to={process.env.PUBLIC_URL + `/contact`}>Support our peace building initiatives </Link></h3>
                                                <p className="donations-list__text">LIFLEA actively encourages the participation of women residing in rural communities by establishing rural women forums in 
                                                Bong and Nimba Counties. LIFLEA collaborates with these forums to advocate for the rights of women.. </p>
                                                <div className="donations-list__progress">
                                                    <div className="bar">
                                                        <div className="bar-inner count-bar" data-percent="36%">
                                                            <div className="count-text">0%</div>
                                                        </div>
                                                    </div>
                                                    <div className="donations-list__goals">
                                                        <p><span>$0</span> Raised</p>
                                                        <p><span>$0.00</span> Goal</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="donations-list__img">
                                            <img src={publicUrl+"assets/images/resources/donations-list-1.jpg"} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Donations List Single */}
                            <div className="donations-list__single">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="donations-list__right">
                                            <div className="donations-list__content">
                                                <div className="donations-list__category">
                                                    <p>Capacity Development</p>
                                                </div>
                                                <h3 className="donations-list__title"><Link to={process.env.PUBLIC_URL + `/contact`}>Scholarship for female law enforcement officers </Link></h3>
                                                <p className="donations-list__text">LIFLEA works in partnership with various organizations to identify and provide support for 
                                                women in law enforcement, enabling them to enhance their professional skills and increase their prospects for career progression.</p>
                                                <div className="donations-list__progress">
                                                    <div className="bar">
                                                        <div className="bar-inner count-bar" data-percent="36%">
                                                            <div className="count-text">0%</div>
                                                        </div>
                                                    </div>
                                                    <div className="donations-list__goals">
                                                        <p><span>$0</span> Raised</p>
                                                        <p><span>$0.00</span> Goal</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="donations-list__img">
                                            <img src={publicUrl+"assets/images/resources/donations-list-2.jpg"} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Donations List Single */}
                            <div className="donations-list__single">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="donations-list__right">
                                            <div className="donations-list__content">
                                                <div className="donations-list__category">
                                                    <p>Mentorship</p>
                                                </div>
                                                <h3 className="donations-list__title"><Link to={process.env.PUBLIC_URL + `/contact`}>Provides opportunities for young female officers </Link></h3>
                                                <p className="donations-list__text">LIFLEA’s Mentorship program provides opportunities for young female officers to learn  and be mentored on the job 
                                                This involves assisting individuals in cultivating their expertise in professional ethics.</p>
                                                <div className="donations-list__progress">
                                                    <div className="bar">
                                                        <div className="bar-inner count-bar" data-percent="36%">
                                                            <div className="count-text">0%</div>
                                                        </div>
                                                    </div>
                                                    <div className="donations-list__goals">
                                                        <p><span>$0</span> Raised</p>
                                                        <p><span>$0.00</span> Goal</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="donations-list__img">
                                            <img src={publicUrl+"assets/images/resources/donations-list-3.jpg"} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Donations List Single */}
                            <div className="donations-list__single">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="donations-list__right">
                                            <div className="donations-list__content">
                                                <div className="donations-list__category">
                                                    <p>Gender Inclusiveness </p>
                                                </div>
                                                <h3 className="donations-list__title"><Link to={process.env.PUBLIC_URL + `/contact`}>Foster gender equality and inclusiveness</Link></h3>
                                                <p className="donations-list__text">Enhancing the capacity of female officers’ through professional development and mentorship 
                                                initiatives. provide security women who lack financial resources the opportunity to enhance their professional skills.</p>
                                                <div className="donations-list__progress">
                                                    <div className="bar">
                                                        <div className="bar-inner count-bar" data-percent="36%">
                                                            <div className="count-text">0%</div>
                                                        </div>
                                                    </div>
                                                    <div className="donations-list__goals">
                                                        <p><span>$0</span> Raised</p>
                                                        <p><span>$0.00</span> Goal</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="donations-list__img">
                                            <img src={publicUrl+"assets/images/resources/donations-list-4.jpg"} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}