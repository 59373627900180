import React from 'react'
import { Link } from'react-router-dom'


export default class UpdateOne extends React.Component {
    
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="update-content-one  about-three">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="update-content-one__left wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                                    <div className="update-content-one__img-box">
                                        <div className="update-content-one__img">
                                            <img src={publicUrl+"assets/images/resources/what-we-do-1.jpg"} alt="" />
                                        </div>
                                        <div className="update-content-one__small-img wow zoomIn animated animated" data-wow-delay="500ms"
                                            data-wow-duration="2500ms">
                                            <img src={publicUrl+"assets/images/resources/"} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="update-content-one__right">
                                    <div className="update-content-one__right-content">
                                        <div className="section-title text-left">
                                            <span className="section-title__tagline">What We Do</span>
                                            {/* <h2 className="section-title__title">Building the capacity of female law enforcement officers for efficiency.</h2> */}
                                        </div>
                                        {/* <p className="update-content-one__text">To increase the likelihood of law enforcement women being eligible for peacekeeping 
                                        missions, a group of over 40 law enforcement women from LNP, LIS, and BCR are currently undergoing defensive driving training 
                                        at the Liberia National Police Training Academy School.</p> */}
                                        <ul className="list-unstyled update-content-one__points">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-comment"></span>
                                                </div>
                                                <div className="text">
                                                    <h4>Advocacy</h4>
                                                    <p>Over the past two decades plus, LIFLEA has sustained its advocacy efforts by continuously amplifying its voice and 
                                                        raising awareness on issues such as sexual gender-based violence, gender discrimination and intimidation affecting 
                                                        not only women in the security sector of Liberia but also women and children across Liberia. It has made concerted 
                                                        efforts to collectively promote women rights and advocate for gender equality and career advancements for female 
                                                        security officers. <br/> <br/>
                                                        LIFLEA has persistently strived to advance the cause of inclusivity, placing great emphasis on 
                                                        the significance of ensuring equal and active involvement of women in law enforcement in the recruitment, employment, 
                                                        promotion, decision-making, and governance processes within the Liberian security sector.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-comment"></span>
                                                </div>
                                                <div className="text">
                                                    <h4>Fostering Sustained Collaboration and Networking</h4>
                                                    <p> Over the past 23 years, LIFLEA has been working with partners, including the Government of Liberia, the Government of 
                                                        Sweden through the Swedish NGO, Kvinna Till Kvinna, IDLO, INL, UN-Women, UNMIL/UNPOL, GBOWEE’s Foundation, WIPNET, 
                                                        WONGOSOL, etc to support efforts aimed at sustaining institutional strengthening. These joint efforts have been focused 
                                                        on supporting initiatives that address the challenges faced by women in law enforcement. <br/> <br/>
                                                        
                                                        Additionally, LIFLEA maintains its commitment to fostering collaboration by consistently networking and coordinating 
                                                        with women associations in security and law enforcement from different institutions in Liberia including those of the 
                                                        private sector. These women associations collaborate via LIFLEA, which serves as the overarching umbrella body facilitating 
                                                        their active involvement with law enforcement agency leaders, government technical experts, and development partners amongst others.</p>
                                                </div>
                                            </li>
                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            {/* Skills contents */}
                <section className="skill-one">
                    <div className="skill-one__container">
                        <div className="skill-one__left">
                            <div className="skill-one__bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/what-we-do-left.jpg)'}}></div>
                            <div className="skill-one__arrow-box">
                                <div className="skill-one__arrow-box-inner"></div>
                            </div>
                        </div>
                        <div className="skill-one__right">
                            <div className="skill-one__bg-two" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/what-we-do-right.jpg)'}}></div>
                            <div className="skill-one__content">
                                <div className="section-title text-left">
                                    <span className="section-title__tagline"></span>
                                    <h2 className="section-title__title">Capacity Development and Career Advancement</h2>
                                </div>
                                <p className="skill-one__text">To increase the likelihood of law enforcement women being eligible for peacekeeping missions, a group of over 40 law enforcement 
                                women from LNP, LIS, and BCR are currently undergoing defensive driving training at the Liberia National Police Training Academy School.  A significant milestone 
                                has been achieved at LNFS as 27 women firefighters are being trained to acquire the necessary skills and technical expertise to operate fire engines and ambulances. 
                                This development marks a groundbreaking opportunity for women at LNFS, who for the first time can now advance themselves and stand the chance to equally compete with 
                                their male counterpart.</p>
                                
                            </div>
                        </div>
                    </div>
                </section>

                {/* FeatureOne Contents */}
                <section className="feature-one">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="100ms">
                                {/* Feature One Single */}
                                <div className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className=""></span>
                                        </div>
                                        <h3 className="feature-one__title">Mentorship</h3>
                                    </div>
                                    <p className="feature-one__text">Drawing upon the knowledge and assistance of seasoned and accomplished female officers who have defied all obstacles to shatter the 
                                    gender barriers in Liberia's predominantly male-dominated security sector, LIFLEA’s Mentorship program provides opportunities for young female officers to learn  
                                    and be mentored on the job. This involves assisting individuals in cultivating their expertise in professional ethics, addressing instances of gender-based violence 
                                    in the workplace, seizing opportunities, and actively participating in various endeavors that enhance their professional abilities and prospects for career progression.</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                                {/* Feature One Single */}
                                <div className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className=""></span>
                                        </div>
                                        <h3 className="feature-one__title">Civic Education</h3>
                                    </div>
                                    <p className="feature-one__text">LIFLEA collaborates with local women's groups, women traditional leaders, and other organizations to carry out outreach activities and 
                                    increase awareness on Sexual and Gender Based Violence (SGBV), Sexual Reproductive Health and Rights (SRHR), peacebuilding, women and children's rights, women's active 
                                    participation in elections, decision-making, and leadership processes. At present, LIFLEA is working closely with the Rural Women Forum in Bong and Nimba counties, led 
                                    by Chief Mary Larteh, to enhance and bolster advocacy efforts led by these organizations </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">
                                {/* Feature One Single */}
                                <div className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className=" "></span>
                                        </div>
                                        <h3 className="feature-one__title">Fostering gender equality and inclusiveness</h3>
                                    </div>
                                    <p className="feature-one__text"> Enhancing the capacity of female officers’ through professional development and mentorship initiatives. Professional development is crucial 
                                    for empowering women in the security sector and offers them the chance to advance their careers and improve their prospects for promotions. Throughout its existence, LIFLEA 
                                    has consistently built partnership and maintained efforts to collaboratively provide security women who lack financial resources the opportunity to enhance their professional 
                                    skills and knowledge. </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                {/* HelpPeopleOne Contents */}
                <section className="help-people">
                    <div className="help-people-bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/what-we-do-bg.jpg)'}}></div>
                    <div className="container">
                        <div className="help-people__inner wow fadeInUp" data-wow-delay="300ms">
                            {/* <div className="help-people__sub-title-box">
                                <p>For Good</p>
                            </div> */}
                            <h3 className="help-people__title">Become Volunteer</h3>
                            <p className="help-people__text">Join LIFLEA’s Mentorship program to provide opportunities for young female officers to learn</p>
                            <Link to={process.env.PUBLIC_URL + `/contact`} className="thm-btn help-people__btn">Get Involve Now</Link>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
