import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterOne from '../common/footer/FooterOne';
import UpdateVersionOne from './UpdateVersionOne';

const WhatWeDo = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="What We Do"
                currentPage="What We Do" 
            />
            <UpdateVersionOne />
            <FooterOne />
        </>
    )
}

export default WhatWeDo;