import React from 'react'
import UpdateContentOne from '../components/update/UpdateContentOne';

const UpdateContents = () => {
    return (
        <>
            <UpdateContentOne />
        </>
    )
}

export default UpdateContents