import React from 'react';
import { Link } from 'react-router-dom';

export default class ProjectDetailsContent extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($('.up-comming-event-two').length) {
            $('.up-comming-event-two .tabs-button-box .tab-btn-item').on('click', function (e) {
              e.preventDefault();
              var target = $($(this).attr('data-tab'));
        
              if ($(target).hasClass('actve-tab')) {
                return false;
              } else {
                $('.up-comming-event-two .tabs-button-box .tab-btn-item').removeClass('active-btn-item');
                $(this).addClass('active-btn-item');
                $('.up-comming-event-two .tabs-content-box .tab-content-box-item').removeClass('tab-content-box-item-active');
                $(target).addClass('tab-content-box-item-active');
              }
            });
        }

    


        if ($(".video-popup").length) {
            $(".video-popup").magnificPopup({
                type: "iframe",
                mainClass: "mfp-fade",
                removalDelay: 160,
                preloader: true,
                fixedContentPos: false,
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
               {/* Recent Event Two Start */}
               <section className="up-comming-event-two">
                    <div className="container">
                        <div className="section-title text-center">
                            <span className="section-title__tagline">Where We Work</span>
                            <h2 className="section-title__title">LIFLEA operates in 10 counties, <br/>with its main office located in Monrovia, <br /> Montserrado County.</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-lg-7">
                                <div className="up-comming-event-two__left">

                                    <div className="up-comming-event-two__top">
                                        <ul className="tabs-button-box list-unstyled up-comming-event-two__list">
                                            <li data-tab="#month-1" className="tab-btn-item active-btn-item">
                                                <p>Headquarters<span></span></p>
                                            </li>
                                            <li data-tab="#month-2" className="tab-btn-item">
                                                <p>County<span>Chapters</span></p>
                                            </li>
                                            <li data-tab="#month-3" className="tab-btn-item">
                                                <p>County<span>Chapters</span></p>
                                            </li>
                                            {/* <li data-tab="#month-4" className="tab-btn-item">
                                                <p>County<span>Chapters</span></p>
                                            </li> */}
                                        </ul>
                                    </div>

                                    <div className="tabs-content-box">
                                        {/* Tab */}
                                        <div className="tab-content-box-item tab-content-box-item-active" id="month-1">
                                            <div className="up-comming-event-two__bottom">
                                                <div className="up-comming-event-two__single">
                                                    <div className="up-comming-event-two__location-box">
                                                        <h5 className="up-comming-event-two__location-title">Monrovia Head Office</h5>
                                                        <ul className="list-unstyled up-comming-event-two__location-list">
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-place"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Opposite to KTC Hotel, Old Matadi, Airfield Sinkor, Liberia</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-back-in-time"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Open 8:00am-17:00pm</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="up-comming-event-two__date-box">
                                                        <p>18</p>
                                                        <span>June</span>
                                                    </div> */}
                                                </div>
                                                {/* <div className="up-comming-event-two__single">
                                                    <div className="up-comming-event-two__location-box">
                                                        <h5 className="up-comming-event-two__location-title">Monrovia Office</h5>
                                                        <ul className="list-unstyled up-comming-event-two__location-list">
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-place"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Old SID Road, Mamba Point Monrovia, Liberia</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-back-in-time"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Open 8:00am-17:00pm</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="up-comming-event-two__date-box">
                                                        <p>18</p>
                                                        <span>June</span>
                                                    </div>
                                                </div> */}
                                                {/* <div className="up-comming-event-two__single">
                                                    <div className="up-comming-event-two__location-box">
                                                        <h5 className="up-comming-event-two__location-title">Morovia Office</h5>
                                                        <ul className="list-unstyled up-comming-event-two__location-list">
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-place"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Old SID Road, Mamba Point Monrovia, Liberia</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-back-in-time"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Open 8am:00-17:00pm</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="up-comming-event-two__date-box">
                                                        <p>18</p>
                                                        <span>June</span>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>

                                        {/* Tab */}
                                        <div className="tab-content-box-item" id="month-2">
                                            <div className="up-comming-event-two__bottom">
                                                <div className="up-comming-event-two__single">
                                                    <div className="up-comming-event-two__location-box">
                                                        <h5 className="up-comming-event-two__location-title">Lofa Head Office</h5>
                                                        <ul className="list-unstyled up-comming-event-two__location-list">
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-place"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Broad Street Voinjama, Lofa County, Liberia </p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-back-in-time"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Open 8am:00-17:00pm</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="up-comming-event-two__date-box">
                                                        <p>18</p>
                                                        <span>June</span>
                                                    </div> */}
                                                </div>
                                                <div className="up-comming-event-two__single">
                                                    <div className="up-comming-event-two__location-box">
                                                        <h5 className="up-comming-event-two__location-title">Gbarpolu Head Office</h5>
                                                        <ul className="list-unstyled up-comming-event-two__location-list">
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-place"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Bopolu city, Gbarpolu County, Liberia</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-back-in-time"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Open 8am:00-17:00pm</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="up-comming-event-two__date-box">
                                                        <p>18</p>
                                                        <span>June</span>
                                                    </div> */}
                                                </div>
                                                <div className="up-comming-event-two__single">
                                                    <div className="up-comming-event-two__location-box">
                                                        <h5 className="up-comming-event-two__location-title">Grand Cape Mount Head Office</h5>
                                                        <ul className="list-unstyled up-comming-event-two__location-list">
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-place"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Seinji, Grand Cape Mount County, Liberia</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-back-in-time"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Open 8am:00-17:00pm</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="up-comming-event-two__date-box">
                                                        <p>18</p>
                                                        <span>June</span>
                                                    </div> */}
                                                </div>
                                                <div className="up-comming-event-two__single">
                                                    <div className="up-comming-event-two__location-box">
                                                        <h5 className="up-comming-event-two__location-title">Bomi County Head Office</h5>
                                                        <ul className="list-unstyled up-comming-event-two__location-list">
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-place"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Tubmanburg city, Bomi County, Liberia</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-back-in-time"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Open 8am:00-17:00pm</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="up-comming-event-two__date-box">
                                                        <p>18</p>
                                                        <span>June</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Tab */}
                                        <div className="tab-content-box-item" id="month-3">
                                            <div className="up-comming-event-two__bottom">
                                                <div className="up-comming-event-two__single">
                                                    <div className="up-comming-event-two__location-box">
                                                        <h5 className="up-comming-event-two__location-title">Margibi County Head Office</h5>
                                                        <ul className="list-unstyled up-comming-event-two__location-list">
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-place"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Kakata city, Margibi County, Liberia</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-back-in-time"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Open 8am:00-17:00pm</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="up-comming-event-two__date-box">
                                                        <p>18</p>
                                                        <span>June</span>
                                                    </div> */}
                                                </div>
                                                <div className="up-comming-event-two__single">
                                                    <div className="up-comming-event-two__location-box">
                                                        <h5 className="up-comming-event-two__location-title">Grand Bassa County Head Office</h5>
                                                        <ul className="list-unstyled up-comming-event-two__location-list">
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-place"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Buchanna city, Grand Bassa County, Liberia</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-back-in-time"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Open 8am:00-17:00pm</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="up-comming-event-two__date-box">
                                                        <p>18</p>
                                                        <span>June</span>
                                                    </div> */}
                                                </div>

                                                <div className="up-comming-event-two__single">
                                                    <div className="up-comming-event-two__location-box">
                                                        <h5 className="up-comming-event-two__location-title"> Bong County Head Office </h5>
                                                        <ul className="list-unstyled up-comming-event-two__location-list">
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-place"> </span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Gbarnga city, Bong County, Liberia</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-back-in-time"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Open 8am:00-17:00pm</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="up-comming-event-two__date-box">
                                                        <p>18</p>
                                                        <span>June</span>
                                                    </div> */}
                                                </div>


                                                <div className="up-comming-event-two__single">
                                                    <div className="up-comming-event-two__location-box">
                                                        <h5 className="up-comming-event-two__location-title">Nimba County Head Office</h5>
                                                        <ul className="list-unstyled up-comming-event-two__location-list">
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-place"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p> Guinea Road, Ganta city, Nimba County, Liberia </p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-back-in-time"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Open 8am:00-17:00pm</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="up-comming-event-two__date-box">
                                                        <p>18</p>
                                                        <span>June</span>
                                                    </div> */}
                                                </div>

                                                <div className="up-comming-event-two__single">
                                                    <div className="up-comming-event-two__location-box">
                                                        <h5 className="up-comming-event-two__location-title">Grand Gedeh County Head Office</h5>
                                                        <ul className="list-unstyled up-comming-event-two__location-list">
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-place"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Zwedru Main Street, Grand Gedeh County, Liberia</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="icon">
                                                                    <span className="icon-back-in-time"></span>
                                                                </div>
                                                                <div className="text">
                                                                    <p>Open 8am:00-17:00pm</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="up-comming-event-two__date-box">
                                                        <p>18</p>
                                                        <span>June</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                <div className="up-comming-event-two__right">
                                    <div className="up-comming-event-two__img">
                                        <img src={publicUrl+"assets/images/resources/up-comming-event-two-1.jpg"} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
               
                {/* Recent Events End */}

                <section className="help-one">
                    <div className="help-one-bg jarallax" data-jarallax data-speed="0.2" data-imgPosition="50% 0%" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/help-one-bg.jpg)'}}></div>
                    <div className="container">
                        <h3 className="help-one__title">How You Can Help Us?</h3>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <div className="help-one__content-box">
                                    <div className="help-one__single">
                                        <div className="help-one__icon">
                                            <span className="icon-donation-1"></span>
                                        </div>
                                        <div className="help-one__content">
                                            <h4>Support law enforcement women</h4>
                                            <p>To carry out outreach activities and increase awareness on Sexual and Gender Based Violence (SGBV)...</p>
                                        </div>
                                    </div>
                                    <div className="help-one__single">
                                        <div className="help-one__icon">
                                            <span className="icon-heart"></span>
                                        </div>
                                        <div className="help-one__content">
                                            <h4>Support Our Digital Litracy Program</h4>
                                            <p>To provides opportunities for young female officers to learn  and be mentored on the job..</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="help-one__content-box">
                                    <div className="help-one__single">
                                        <div className="help-one__icon">
                                            <span className="icon-donation"></span>
                                        </div>
                                        <div className="help-one__content">
                                            <h4>Support Our Capacity Development</h4>
                                            <p>To provide support for women in law enforcement, enabling them to enhance their professional skills.</p>
                                        </div>
                                    </div>
                                    <div className="help-one__single">
                                        <div className="help-one__icon">
                                            <span className="icon-bankruptcy"></span>
                                        </div>
                                        <div className="help-one__content">
                                            <h4>Help Maintain Strong  Networking</h4>
                                            <p>Help LIFLEA maintain its commitment to fostering helthy collaboration amongs women associations...</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Cta One Start */}  
                <section className="cta-one">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="cta-one__inner">
                                    <div className="cta-one__left">
                                        <div className="cta-one__icon">
                                            <span className="icon-help"></span>
                                        </div>
                                        <h2 className="cta-one__title">Join LIFLEA's gender <br/>equality and inclusiveness campaign</h2>
                                    </div>
                                    <div className="cta-one__right">
                                        <Link to={process.env.PUBLIC_URL + `/contact`} className="thm-btn cta-one__btn">become a volunteer</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Cta One End */}

                {/* Project Details Start */}
                {/* <section className="project-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <div className="project-details__left">
                                    <div className="project-details__img">
                                        <img src={publicUrl+"assets/images/project/project-details-img-1.jpg"} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="project-details__right">
                                    <h3 className="project-details__title">Information</h3>
                                    <ul className="list-unstyled project-details__list">
                                        <li>
                                            <div className="left">
                                                <h4>Project Name:</h4>
                                            </div>
                                            <div className="right">
                                                <p>Children of Helps Nonpofit</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="left">
                                                <h4>Category:</h4>
                                            </div>
                                            <div className="right">
                                                <p>Nonpofit Poor.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="left">
                                                <h4>Client</h4>
                                            </div>
                                            <div className="right">
                                                <p>Children of Helps .</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="left">
                                                <h4>Location:</h4>
                                            </div>
                                            <div className="right">
                                                <p>Rangpur,Berakuti</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="left">
                                                <h4>Share:</h4>
                                            </div>
                                            <div className="right">
                                                <div className="project-details__social">
                                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                                    <a href="#"><i className="fab fa-facebook"></i></a>
                                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="project-details__bottom">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="project-details__text-box">
                                        <p className="project-details__text-1">Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry orem Ipsum has been the industry's standard dummy text ever
                                            since the 1500s, when an unknown printer took a galley of type and scrambled it to
                                            make a type specimen book. It has survived not only five centuries, but also the
                                            leap into electronic typesetting, remaining essentially unchanged. It was
                                            popularised in the 1960s with the release of Letraset sheets containin Lorem Ipsum
                                            passages, and more recently with desktop publishing software like Aldus PageMaker
                                            including .</p>
                                        <p className="project-details__text-2">Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry orem Ipsum has been the industry's standard dummy text ever
                                            since the 1500s, when an unknown printer took a galley of type .</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* Project Details End */}

                {/* video One Start */}
                {/* <section className="video-one video-three">
                    <div className="container">
                        <div className="video-one__content-box">
                            <div className="video-two-shape sauare-mover"></div>
                            <div className="video-one-bg jarallax" data-jarallax data-speed="0.2" data-imgPosition="50% 0%"
                                style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/video-one-bg.jpg)'}}></div>
                            <div className="video-one__inner">
                                <div className="video-one__video-link">
                                    <a href="https://www.youtube.com/watch?v=Get7rqXYrbQ" className="video-popup">
                                        <div className="video-one__video-icon">
                                            <span className="fa fa-play"></span>
                                            <i className="ripple"></i>
                                        </div>
                                    </a>
                                </div>
                                <h3 className="video-one__title">Welcome To Our We Donation <br /> Video Poor.</h3>
                                <p className="video-one__text">If you are going to use a passage of Lorem Ipsum, you <br /> need to be
                                    sure there isn't anything </p>
                                <Link to={process.env.PUBLIC_URL + `/about`} className="thm-btn video-one__btn">Video More</Link>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* video One End */}

                {/* Blog Section Two Start */}
                {/* <section className="blog-one blog-two">
                    <div className="container">
                        <div className="section-title text-center">
                            <span className="section-title__tagline">Our Blog Post</span>
                            <h2 className="section-title__title">We Best To Largest <br /> Of This Blog.</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms"> */}
                                {/* Blog One single */}
                                {/* <div className="blog-one__single">
                                    <div className="blog-one__img">
                                        <img src={publicUrl+"assets/images/blog/blog-1-1.jpg"} alt="" />
                                        <Link to={process.env.PUBLIC_URL + `/blog-details`}>
                                            <span className="blog-one__plus"></span>
                                        </Link>
                                    </div>
                                    <div className="blog-one__content">
                                        <div className="blog-one__meta">
                                            <div className="blog-one__cat">
                                                <p>Cyclone</p>
                                            </div>
                                            <div className="blgo-one__date">
                                                <p> <span className="icon-calendar-1"></span> Nov 09 2022</p>
                                            </div>
                                        </div>
                                        <h3 className="blog-one__title"><Link to={process.env.PUBLIC_URL + `/blog-details`}>will provide an ats resume writing and Blog. </Link></h3>
                                        <p className="blog-one__text">Lorem ipsum dolor sit amet,elit, sed do eiusmod tempor incididunt ut labore et dolore aliqu</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms"> */}
                                {/* Blog One single */}
                                {/* <div className="blog-one__single">
                                    <div className="blog-one__img">
                                        <img src={publicUrl+"assets/images/blog/blog-1-2.jpg"} alt="" />
                                        <Link to={process.env.PUBLIC_URL + `/blog-details`}>
                                            <span className="blog-one__plus"></span>
                                        </Link>
                                    </div>
                                    <div className="blog-one__content">
                                        <div className="blog-one__meta">
                                            <div className="blog-one__cat">
                                                <p>Homeless</p>
                                            </div>
                                            <div className="blgo-one__date">
                                                <p> <span className="icon-calendar-1"></span> Nov 09 2022</p>
                                            </div>
                                        </div>
                                        <h3 className="blog-one__title"><Link to={process.env.PUBLIC_URL + `/blog-details`}>Leverage agile frameworks to provide</Link></h3>
                                        <p className="blog-one__text">Lorem ipsum dolor sit amet,elit, sed do eiusmod tempor incididunt ut labore et dolore aliqu</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms"> */}
                                {/* Blog One single */}
                                {/* <div className="blog-one__single">
                                    <div className="blog-one__img">
                                        <img src={publicUrl+"assets/images/blog/blog-1-3.jpg"} alt="" />
                                        <Link to={process.env.PUBLIC_URL + `/blog-details`}>
                                            <span className="blog-one__plus"></span>
                                        </Link>
                                    </div>
                                    <div className="blog-one__content">
                                        <div className="blog-one__meta">
                                            <div className="blog-one__cat">
                                                <p>Donate</p>
                                            </div>
                                            <div className="blgo-one__date">
                                                <p> <span className="icon-calendar-1"></span> Nov 09 2022</p>
                                            </div>
                                        </div>
                                        <h3 className="blog-one__title"><Link to={process.env.PUBLIC_URL + `/blog-details`}>Bring to the table win-win survival</Link></h3>
                                        <p className="blog-one__text">Lorem ipsum dolor sit amet,elit, sed do eiusmod tempor incididunt ut labore et dolore aliqu</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* Blog Section Two End   */}
            </>
        )
    }                                                                                                                            
}