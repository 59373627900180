import React, { useEffect } from 'react';
import { FaAngleUp } from "react-icons/fa";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import WOW from 'wowjs';

//Pages
import ScrollToTopRoute from './components/scroll-to-top-route/ScrollToTopRoute';
import About from "./programs/About";
import BlogDetails from "./programs/BlogDetails";
import BlogSidebar from "./programs/BlogSidebar";
import BlogVersionOne from "./programs/BlogVersionOne";
import BlogVersionTwo from "./programs/BlogVersionTwo";
import Contact from "./programs/Contact";
import DonateNow from "./programs/DonateNow";
import DonationDetails from "./programs/DonationDetails";
import DonationList from "./programs/DonationList";
import Error from "./programs/Error";
import Events from "./programs/Events";
import Gallery from "./programs/Gallery";
import GetInvolved from "./programs/GetInvolved";
import HomeDefault from "./programs/HomeDefault";
import HomeThree from "./programs/HomeThree";
import HomeTwo from "./programs/HomeTwo";
import Team from "./programs/Team";
import TeamDetails from "./programs/TeamDetails";
import Testimonials from "./programs/Testimonials";
import UpdateContents from './programs/UpdateContents';
import WhatWeDo from "./programs/WhatWeDo";
import WhereWeWork from "./programs/WhereWeWork";

import "swiper/css";
import 'swiper/css/effect-fade';
import "swiper/css/navigation";
import "swiper/css/pagination";
import UpdateVersionOne from './programs/UpdateVersionOne';
import UpdateVersionTwo from './programs/UpdateVersionTwo';

function App() {
  useEffect(() => {
    new WOW.WOW({
        live: false
      }).init();
    }, []
  );
  return (
    <Router>
      <ScrollToTopRoute />
        <Routes>
            <Route path={`${process.env.PUBLIC_URL}/`} exact element={<HomeDefault />} />
            <Route path={`${process.env.PUBLIC_URL}/home-two`} exact element={<HomeTwo />} />
            <Route path={`${process.env.PUBLIC_URL}/home-three`} exact element={<HomeThree />} />
            <Route path={`${process.env.PUBLIC_URL}/about`} exact element={<About />} />
            <Route path={`${process.env.PUBLIC_URL}/team`} exact element={<Team />} />
            <Route path={`${process.env.PUBLIC_URL}/team-details`} exact element={<TeamDetails />} />
            <Route path={`${process.env.PUBLIC_URL}/what-we-do`} exact element={<WhatWeDo />} />
            <Route path={`${process.env.PUBLIC_URL}/where-we-work`} exact element={<WhereWeWork />} />
            <Route path={`${process.env.PUBLIC_URL}/update-contents`} exact element={<UpdateContents />} />
            <Route path={`${process.env.PUBLIC_URL}/update-v-1`} exact element={<UpdateVersionOne />} />
            <Route path={`${process.env.PUBLIC_URL}/update-v-2`} exact element={<UpdateVersionTwo />} />
            <Route path={`${process.env.PUBLIC_URL}/testimonials`} exact element={<Testimonials />} />
            <Route path={`${process.env.PUBLIC_URL}/gallery`} exact element={<Gallery />} />
            <Route path={`${process.env.PUBLIC_URL}/get-involved`} exact element={<GetInvolved />} />
            <Route path={`${process.env.PUBLIC_URL}/donation-list`} exact element={<DonationList />} />
            <Route path={`${process.env.PUBLIC_URL}/donation-details`} exact element={<DonationDetails />} />
            <Route path={`${process.env.PUBLIC_URL}/donate-now`} exact element={<DonateNow />} />
            <Route path={`${process.env.PUBLIC_URL}/Events`} exact element={<Events />} />
            <Route path={`${process.env.PUBLIC_URL}/blog-v-1`} exact element={<BlogVersionOne />} />
            <Route path={`${process.env.PUBLIC_URL}/blog-v-2`} exact element={<BlogVersionTwo />} />
            <Route path={`${process.env.PUBLIC_URL}/blog-sidebar`} exact element={<BlogSidebar />} />
            <Route path={`${process.env.PUBLIC_URL}/blog-details`} exact element={<BlogDetails />} />
            <Route path={`${process.env.PUBLIC_URL}/contact`} exact element={<Contact />} />
            <Route path={`${process.env.PUBLIC_URL}/*`} exact element={<Error />} />
        </Routes>
        <ScrollToTop className="scrollUp" smooth top="1500" component={<FaAngleUp />} />
    </Router>
  );
}

export default App;