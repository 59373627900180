import React from 'react';
import { Link } from 'react-router-dom';

export default class FooterOne extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($(".footer-widget-one__twitter-feed-content").length) {
            $(".footer-widget-one__twitter-feed-content").owlCarousel({
                loop: true,
                autoplay: true,
                margin: 30,
                nav: false,
                dots: true,
                smartSpeed: 500,
                autoplayTimeout: 10000,
                navText: ["<span class=\"fa fa-angle-left\"></span>","<span class=\"fa fa-angle-right\"></span>"],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 1
                    },
                    991: {
                        items: 1
                    },
                    1200: {
                        items: 1
                    }
                }
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <footer className="site-footer-one">
                    <div className="site-footer-one__top">
                        <div className="container">
                            <div className="row">

                                <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                                    <div className="footer-widget__column footer-widget-one__about">
                                        <div className="footer-widget-one__about-logo">
                                            <Link to={process.env.PUBLIC_URL + `/`}><img src={publicUrl+"assets/images/resources/liflea.png"} alt="" /></Link>
                                        </div>
                                        <p className="footer-widget-one__about-text">
                                        LIFLEA work with stakeholders to advance policy reforms and engage in advocacy efforts 
                                        that focus on gender mainstreaming of women in law enforcement in Liberia
                                        </p>
                                        <ul className="list-unstyled footer-widget-one__list">
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-place"></span>
                                                </div>
                                                <div className="text">
                                                    <p>Old Matadi, Opposite to KTC Hotel Airfield, Monrovia.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-envelope"></span>
                                                </div>
                                                <div className="text">
                                                    <p><a href="mailto:demo@example.com">info@liflea.org</a></p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-call"></span>
                                                </div>
                                                <div className="text">
                                                    <p><a href="tel:09969569535">+231 775 586 564</a></p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
                                    <div className="footer-widget__column footer-widget-one__gallery clearfix">
                                        <h3 className="footer-widget-one__title">Gallery</h3>
                                        <ul className="footer-widget-one__gallery-list list-unstyled clearfix">
                                            <li>
                                                <div className="footer-widget-one__gallery-img">
                                                    <img src={publicUrl+"assets/images/resources/footer-widget-1.jpg"} alt="" />
                                                    <Link to={process.env.PUBLIC_URL + `/#`}><span className="fa fa-link"></span></Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-widget-one__gallery-img">
                                                    <img src={publicUrl+"assets/images/resources/footer-widget-2.jpg"} alt="" />
                                                    <Link to={process.env.PUBLIC_URL + `/#`}><span className="fa fa-link"></span></Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-widget-one__gallery-img">
                                                    <img src={publicUrl+"assets/images/resources/footer-widget-3.jpg"} alt="" />
                                                    <Link to={process.env.PUBLIC_URL + `/#`}><span className="fa fa-link"></span></Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-widget-one__gallery-img">
                                                    <img src={publicUrl+"assets/images/resources/footer-widget-4.jpg"} alt="" />
                                                    <Link to={process.env.PUBLIC_URL + `/#`}><span className="fa fa-link"></span></Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-widget-one__gallery-img">
                                                    <img src={publicUrl+"assets/images/resources/footer-widget-5.jpg"} alt="" />
                                                    <Link to={process.env.PUBLIC_URL + `/#`}><span className="fa fa-link"></span></Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-widget-one__gallery-img">
                                                    <img src={publicUrl+"assets/images/resources/footer-widget-6.jpg"} alt="" />
                                                    <Link to={process.env.PUBLIC_URL + `/#`}><span className="fa fa-link"></span></Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-widget-one__gallery-img">
                                                    <img src={publicUrl+"assets/images/resources/footer-widget-7.jpg"} alt="" />
                                                    <Link to={process.env.PUBLIC_URL + `/#`}><span className="fa fa-link"></span></Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-widget-one__gallery-img">
                                                    <img src={publicUrl+"assets/images/resources/footer-widget-8.jpg"} alt="" />
                                                    <Link to={process.env.PUBLIC_URL + `/#`}><span className="fa fa-link"></span></Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-widget-one__gallery-img">
                                                    <img src={publicUrl+"assets/images/resources/footer-widget-9.jpg"} alt="" />
                                                    <Link to={process.env.PUBLIC_URL + `/#`}><span className="fa fa-link"></span></Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                                    <div className="footer-widget__column footer-widget-one__latest-works clearfix">
                                        <h3 className="footer-widget-one__title">Latest News</h3>
                                        <ul className="footer-widget-one__latest-works-list list-unstyled clearfix">
                                            <li>
                                                <div className="footer-widget-one__latest-works-content">
                                                    <h4 className="footer-widget-one__latest-works-title">
                                                        <Link to={process.env.PUBLIC_URL + `/blog-v-1`}>Celebrating women in Law Enforcement and the Security Sector</Link>
                                                    </h4>
                                                    <p className="footer-widget-one__latest-works-date">
                                                        June 13, 20224
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="footer-widget-one__latest-works-content">
                                                    <h4 className="footer-widget-one__latest-works-title">
                                                        <Link to={process.env.PUBLIC_URL + `/blog-v-1`}>Continued Digital Litracy Trainning for LFS</Link>
                                                    </h4>
                                                    <p className="footer-widget-one__latest-works-date">
                                                        March 6, 20224
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                                    <div className="footer-widget__column footer-widget-one__twitter-feed clearfix">
                                        <h3 className="footer-widget-one__title">Social Media Feed</h3>
                                        <div className="owl-carousel owl-theme thm-owl__carousel footer-widget-one__twitter-feed-content">
                                            <div className="footer-widget-one__twitter-feed-single">
                                                <p className="footer-widget-one__twitter-feed-text">Follow the main Liberia Female Law Enforcement Association (LIFLEA) account for news, updates and service alerts. Get tips and information about our program and more. 
                                                   <a href="https://www.facebook.com/p/Liberia-Female-Law-Enforcement-Association-LIFLEA-100069295507858/?paipv=0&eav=AfYaUM_Xm4ARXU_7AQofVV4gftD8YlWVzJz7GijtKAKAGdjp3zdpvGvSbXEH5BWGPks&_rdr">http://facebook.com</a></p>
                                                <div className="footer-widget-one__twitter-feed-bottom">
                                                    {/* <div className="footer-widget-one__twitter-feed-icon">
                                                        <span className="fab fa-twitter"></span>
                                                    </div> */}
                                                    <div className="footer-widget__social">
                                                        <a href="https://www.facebook.com/p/Liberia-Female-Law-Enforcement-Association-LIFLEA-100069295507858/?paipv=0&eav=AfYaUM_Xm4ARXU_7AQofVV4gftD8YlWVzJz7GijtKAKAGdjp3zdpvGvSbXEH5BWGPks&_rdr"><i className="fab fa-facebook"></i></a>
                                                        <a href="#"><i className="fab fa-twitter"></i></a>
                                                        <a href="#"><i className="fab fa-youtube"></i></a>
                                                        <a href="#"><i className="fab fa-instagram"></i></a>
                                                        <a href="#"><i className="fab fa-whatsapp"></i></a>
                                                    </div>
                                                     
                                                    {/* <div className="footer-widget-one__twitter-feed-details">
                                                        <h5 className="footer-widget-one__twitter-feed-name">John Smith
                                                            <span>@unicktheme</span></h5>
                                                        <p className="footer-widget-one__twitter-feed-time">18 Hours Ago</p>
                                                    </div> */}
                                                       
                                                </div>
                                            </div>
                                            {/* <div className="footer-widget-one__twitter-feed-single">
                                                <p className="footer-widget-one__twitter-feed-text">Lorem ipsum is simply free text
                                                    dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor
                                                    incididunt <a href="#">http://t.twitter.com</a></p>
                                                <div className="footer-widget-one__twitter-feed-bottom">
                                                    <div className="footer-widget-one__twitter-feed-icon">
                                                        <span className="fab fa-twitter"></span>
                                                    </div>
                                                    <div className="footer-widget-one__twitter-feed-details">
                                                        <h5 className="footer-widget-one__twitter-feed-name">Kavin Smith
                                                            <span>@unicktheme</span></h5>
                                                        <p className="footer-widget-one__twitter-feed-time">18 Hours Ago</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className="footer-widget-one__twitter-feed-single">
                                                <p className="footer-widget-one__twitter-feed-text">Lorem ipsum is simply free text
                                                    dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor
                                                    incididunt <a href="#">http://t.twitter.com</a></p>
                                                <div className="footer-widget-one__twitter-feed-bottom">
                                                    <div className="footer-widget-one__twitter-feed-icon">
                                                        <span className="fab fa-twitter"></span>
                                                    </div>
                                                    <div className="footer-widget-one__twitter-feed-details">
                                                        <h5 className="footer-widget-one__twitter-feed-name">Sara Albart
                                                            <span>@unicktheme</span></h5>
                                                        <p className="footer-widget-one__twitter-feed-time">18 Hours Ago</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="site-footer__bottom">
                        <div className="container">
                            <div className="site-footer__bottom-inner">
                                <p className="site-footer__bottom-text">© 2024 Copyright LIFLEA | Design by <a href="https://rhotelhub.org">rhotelhub</a></p>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}