import React from 'react';

export default class ContactMap extends React.Component {
    render(){
        return (
            <>
                <section className="contact-page-google-map">
                    <iframe
                        title="Address"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15863.05614353424!2d-10.7787887!3d6.29471!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf0a0354aca65a3d%3A0xb169acf3cc459640!2sRhotel%20Innovation%20House!5e0!3m2!1sen!2s!4v1712763589768!5m2!1sen!2s"
                        className="contact-page-google-map__one" allowfullscreen></iframe>

                </section>
            </>
        )
    }
}