import React from 'react';
import { Link } from 'react-router-dom';

export default class VideoOne extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($(".video-popup").length) {
            $(".video-popup").magnificPopup({
                type: "iframe",
                mainClass: "mfp-fade",
                removalDelay: 160,
                preloader: true,
                fixedContentPos: false,
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>

                <section className="video-one">
                    <div className="video-one__content-box">
                        <div className="video-one-shape sauare-mover"></div>
                        <div className="video-one-bg jarallax" data-jarallax data-speed="0.2" data-imgPosition="50% 0%" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/video-1.jpg)'}}></div>
                        <div className="container">
                            <div className="video-one__inner">
                                <div className="video-one__video-link">
                                    <a href="https://www.youtube.com/watch?v=YWg94noUhho" className="video-popup">
                                        <div className="video-one__video-icon">
                                            <span className="fa fa-play"></span>
                                            <i className="ripple"></i>
                                        </div>
                                    </a>
                                </div>
                                <h3 className="video-one__title">Welcome To Our Project <br /> Impact Video.</h3>
                                <p className="video-one__text">This captures assisting of individuals in cultivating their expertise in professional <br />ethics, 
                                addressing instances of gender-based violence in the workplace, seizing opportunities,<br /> and actively participating in various
                                 endeavors that enhance their professional abilities and <br />prospects for career progression.. </p>
                                <Link to={process.env.PUBLIC_URL + `/what-we-do`} className="thm-btn video-one__btn">Video More</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}