import React from 'react';
import { Link } from 'react-router-dom';

export default class FeatureTwo extends React.Component {
    render(){
        return (
            <>
                <section className="feature-one">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="100ms">
                                {/* Feature One Single */}
                                <div className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className=""></span>
                                        </div>
                                        <h3 className="feature-one__title"><Link to={process.env.PUBLIC_URL + `/what-we-do`}>Who We Are</Link></h3>
                                    </div>
                                    <p className="feature-one__text">The Liberia Female Law Enforcement Association (LIFLEA) is a non-governmental 
                                    and non-for-profit organization established on December 8, 2000, as the umbrella organization to protect and promote 
                                    the interest and wellbeing of female law enforcement officers. Over the past twenty-three years, LIFLEA has consistently 
                                    worked with stakeholders to advance policy reforms and engage in continuous advocacy efforts that focus on gender .</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                                {/* Feature One Single */}
                                <div className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className=""></span>
                                        </div>
                                        <h3 className="feature-one__title"><Link to={process.env.PUBLIC_URL + `/where-we-work`}>Where We Are</Link></h3>
                                    </div>
                                    <p className="feature-one__text">At present, LIFLEA operates in 10 counties, with its main office located in 
                                    Monrovia, Montserrado County. In these counties, LIFLEA has persistently strived to advance the cause of inclusivity, 
                                    placing great emphasis on the significance of ensuring equal and active involvement of women in law enforcement in the 
                                    recruitment, employment, promotion, decision-making, and governance processes within the Liberian security sector. </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">
                                {/* Feature One Single */}
                                <div className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className=" "></span>
                                        </div>
                                        <h3 className="feature-one__title"><Link to={process.env.PUBLIC_URL + `/what-we-do`}>What We Do</Link></h3>
                                    </div>
                                    <p className="feature-one__text"> Over the past 23 years, LIFLEA has been working with partners, including the Government 
                                    of Liberia, , the Government of Sweden through the Swedish NGO, Kvinna Till Kvinna, IDLO, INL, UN-Women, UNMIL/UNPOL, GBOWEE’s 
                                    Foundation, WIPNET, WONGOSOL, etc to support efforts aimed at sustaining institutional strengthening. These joint efforts have 
                                    been focused on supporting initiatives that address the challenges faced by women in law enforcement. </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        )
    }
}