import React from 'react';
import { Link } from 'react-router-dom';
import { EffectFade, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

export default class HeroOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="main-slider">
                    <Swiper 
                        loop={true}
                        slidesPerView={1}
                        effect="fade"
                        autoplay={{delay: 500}}
                        modules={[EffectFade, Pagination, Navigation]}
                        pagination={{
                            el: "#main-slider-pagination",
                            type: "bullets",
                            clickable: true
                        }}
                        navigation={{
                            nextEl: "#main-slider__swiper-button-next",
                            prevEl: "#main-slider__swiper-button-prev"
                        }}
                        className="swiper-container thm-swiper__slider">

                        <div className="swiper-wrapper">

                            {/* Start Swiper Slide Single */}
                            <SwiperSlide className="swiper-slide">
                                <div className="image-layer" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/main-hero-1.jpg)'}}></div>
                                <div className="main-slider-shape-1"></div>
                                <div className="main-slider-shape-2"></div>
                                <div className="main-slider-shape-3 zoominout"></div>
                                <div className="main-slider-shape-4 zoominout-2"></div>
                                <div className="main-slider-shape-5">
                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-5.png"} alt="" />
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                            <div className="main-slider__content">
                                                <h2>We advance <span>institutional</span> responsiveness and advocate for women in security sector.</h2>
                                                <p>LIFLEA strives to advance inclusivity, placing greater emphasis on supporting efforts to ensure equal participation and representation 
                                                 of women in decision-making positions and governance processes in the security sector. </p>
                                                <Link to={process.env.PUBLIC_URL + `/about`} className="thm-btn main-slider__btn">Leran More</Link>
                                                <div className="main-slider-arrow">
                                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-1.png"} className="float-bob-x" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* End Swiper Slide Single */}

                            {/* Start Swiper Slide Single */}
                            <SwiperSlide className="swiper-slide">
                                <div className="image-layer" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/main-hero-2.jpg)'}}></div>
                                <div className="main-slider-shape-1"></div>
                                <div className="main-slider-shape-2"></div>
                                <div className="main-slider-shape-3 zoominout"></div>
                                <div className="main-slider-shape-4 zoominout-2"></div>
                                <div className="main-slider-shape-5">
                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-5.png"} alt="" />
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                            <div className="main-slider__content">
                                            <h2>We enhance <span>Capacity</span> Development, mentorship and empowerment.</h2>
                                                <p>LIFLEA works in partnership with various organizations to identify and provide support for<br /> women in law ennforcement,
                                                enabling them to enhance their professional skills and increase their prospects for career progression.</p>
                                                <Link to={process.env.PUBLIC_URL + `/what-we-do`} className="thm-btn main-slider__btn">Leran More</Link>
                                                <div className="main-slider-arrow">
                                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-1.png"} className="float-bob-x" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* End Swiper Slide Single */}

                            {/* Start Swiper Slide Single */}
                            <SwiperSlide className="swiper-slide">
                                <div className="image-layer" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/main-hero-3.jpg)'}}></div>
                                <div className="main-slider-shape-1"></div>
                                <div className="main-slider-shape-2"></div>
                                <div className="main-slider-shape-3 zoominout"></div>
                                <div className="main-slider-shape-4 zoominout-2"></div>
                                <div className="main-slider-shape-5">
                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-5.png"} alt="" />
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7">
                                            <div className="main-slider__content">
                                            <h2>We ensure <span>Advocacy</span> for all female law enforcement officers.</h2>
                                                <p>Over the past two decades plus, LIFLEA has sustained its advocacy efforts by continuously <br/>amplifying  its voice and raising awareness
                                                on issues such as sexual gender-based violence, <br/>gender discrimination and intimidation affecting not only   
                                                women in the security sector of Liberia but also women and children across Liberia. </p>
                                                <Link to={process.env.PUBLIC_URL + `/what-we-do`} className="thm-btn main-slider__btn">Leran More</Link>
                                                <div className="main-slider-arrow">
                                                    <img src={publicUrl+"assets/images/shapes/main-slider-shape-1.png"} className="float-bob-x" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* End Swiper Slide Single */}

                        </div>

                        <div className="swiper-pagination" id="main-slider-pagination"></div>

                        <div className="main-slider__nav">
                            <div className="swiper-button-prev" id="main-slider__swiper-button-next">
                                <i className="fa fa-long-arrow-alt-left"></i>
                            </div>
                            <div className="swiper-button-next" id="main-slider__swiper-button-prev">
                                <i className="fa fa-long-arrow-alt-right"></i>
                            </div>
                        </div>

                    </Swiper>
                </section>
            </>
        )
    }
}