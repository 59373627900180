import React from 'react';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BlogSectionThree from '../components/blog/BlogSectionThree';

const BlogVersionOne = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="News"
                currentPage="News" 
            />
            <BlogSectionThree />
            <FooterOne />
        </>
    )
}

export default BlogVersionOne;