import React from 'react';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SingleBlogDetails from '../components/single-blog/SingleBlogDetails';

const BlogDetails = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="News details"
                currentPage="News details" 
            />
            <SingleBlogDetails />
            <FooterOne />
        </>
    )
}

export default BlogDetails;